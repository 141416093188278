import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Tooltip,
  Checkbox,
  DatePicker,
  Modal,
  Divider,
  List,
  Tag,
} from 'antd';

import { dinheiroMask, porcentagemMask } from 'masks-br';
import { Link, useHistory } from 'react-router-dom';
import { MdOpenInNew, MdCancel } from 'react-icons/md';
import { format, parseISO } from 'date-fns';

import moment from 'moment';
import { FaUserAlt } from 'react-icons/fa';
import Paragraph from 'antd/lib/typography/Paragraph';
import PageHeader from '../../../components/PageHeader';
import CardCollapse from '../../../components/CardCollapse';
import Table from '../../../components/Table';
import Pagina from '../../../types/Pagina';
import { fetchApi } from '../../../services/api';
import Card, { TextType } from '../../../components/Card';
import { usePermission } from '../../../hooks/usePermissions';
import { setQueryParams, useQueryParams } from '../../../utils/UrlQuery';

const { RangePicker } = DatePicker;

interface Dashboard {
  valor_comissao: number;
  qtde_comissoes: number;
  qtde_transferencias: number;
  saldo: number;
  saldo_type?: TextType;
}

const ComissaoPesquisar: React.FC = () => {
  const [form] = Form.useForm();
  const [form_filters] = Form.useForm();
  const query_values = useQueryParams();
  const history = useHistory();
  const { getPermissions } = usePermission();

  const permissoes = getPermissions();

  const [comissoesPendentes, setComissoesPendentes] = useState<Pagina>(
    {} as Pagina,
  );
  const [comissoesPendentesDashboard, setComissoesPendentesDashboard] =
    useState<Dashboard>({} as Dashboard);
  const [transferenciasSelecionadas, setTransferenciasSelecionadas] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [filtros, setFiltros] = useState<any | undefined>(undefined);

  // const [borderScroll, setBorderScroll] = useState<string>('');

  const [idTransacaoPagamentoManual, setIdTransacaoPagamentoManual] =
    useState<boolean>(false);

  const [formPagamentoManual] = Form.useForm();

  const dashinfo: Dashboard = useMemo(() => {
    if (!transferenciasSelecionadas.length) {
      const saldo_type =
        comissoesPendentesDashboard.saldo >=
        comissoesPendentesDashboard.valor_comissao
          ? 'success'
          : 'danger';

      return {
        valor_comissao: comissoesPendentesDashboard.valor_comissao,
        qtde_comissoes: comissoesPendentesDashboard.qtde_comissoes,
        qtde_transferencias: comissoesPendentesDashboard.qtde_transferencias,
        saldo: comissoesPendentesDashboard.saldo,
        saldo_type,
      };
    }

    const comissoes = comissoesPendentes.data.filter(comi =>
      transferenciasSelecionadas.includes(comi.id_transacao),
    );

    const transferencias: string[] = [];

    const qtde_comissoes = comissoes.length;
    const valor_comissao = comissoes.reduce(
      (prev, item) => prev + item.valor_comissao,
      0,
    );
    comissoes.forEach(item => {
      if (!transferencias.includes(item.id_usuario)) {
        transferencias.push(item.id_usuario);
      }
    });

    const type =
      comissoesPendentesDashboard.saldo >= valor_comissao
        ? 'success'
        : 'danger';

    return {
      qtde_comissoes,
      qtde_transferencias: transferencias.length,
      valor_comissao,
      saldo: comissoesPendentesDashboard.saldo,
      saldo_type: type,
    };
  }, [comissoesPendentesDashboard, transferenciasSelecionadas]);

  useEffect(() => {
    let data;

    if (query_values.data_inicio && query_values.data_fim) {
      data = [moment(query_values.data_inicio), moment(query_values.data_fim)];
    }

    setFiltros({
      pagina: 1,
      porPagina: 25,
      ...query_values,
      data,
    });
    form_filters.setFieldsValue({ ...query_values, data });

    Promise.all([
      fetchComissoesPendentesDashboard(),
      fetchComissoesPendentes(),
    ]).then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (filtros) {
      fetchComissoesPendentes();
    }
  }, [filtros]);

  function fetchComissoesPendentes() {
    return fetchApi({
      url: '/comissao/pendente',
      method: 'get',
      query_params: filtros,
      onSuccess: data => {
        const response_data = data;

        response_data.data = response_data.data.map((item: any) => ({
          ...item,
          key: item.id_transacao,
          id_carrinho_checkout: item?.checkout_id || item?.id_carrinho,
          is_checkout: Boolean(item?.checkout_id),
        }));

        setComissoesPendentes(response_data);
      },
    });
  }

  function handleSetFiltroValues(data: any) {
    const [data_inicio, data_fim] = [...(data?.data || [])];

    delete data.data;

    const final = {
      ...filtros,
      ...data,
      data_inicio: data_inicio ? data_inicio.format('YYYY-MM-DD') : undefined,
      data_fim: data_fim ? data_fim.format('YYYY-MM-DD') : undefined,
    };

    setQueryParams(final);
    setFiltros(final);
  }

  function fetchComissoesPendentesDashboard() {
    return fetchApi({
      url: '/comissao/dashboard',
      method: 'get',
      onSuccess: data => setComissoesPendentesDashboard(data),
    });
  }

  function handleSubmitComissao(data: {
    senha: string;
    transfer_all: boolean;
  }) {
    return fetchApi({
      url: '/comissao/pendente',
      method: 'post',
      data: {
        ...data,
        id_transacoes: transferenciasSelecionadas,
      },
      messages: {
        loading: 'Enviando comissões para pagamento, aguarde...',
        success: 'Pagamento de comissões enviado com sucesso',
        error: 'Erro ao enviar pagamento de comissões, tente novamente!',
      },
      onSuccess: () => {
        history.push('/carrinho');
      },
    });
  }

  function handleRemoveComissao(id_transacao: string) {
    return fetchApi({
      url: `/comissao/${id_transacao}`,
      method: 'delete',
      messages: {
        loading: 'Removendo comissão para pagamento, aguarde...',
        success: 'Comissões removida com sucesso',
        error: 'Erro ao remover comissões, tente novamente!',
      },
      onSuccess: () => {
        Promise.all([
          fetchComissoesPendentes(),
          fetchComissoesPendentesDashboard(),
        ]);
        setTransferenciasSelecionadas([]);
      },
    });
  }

  function handlePagamentoManual(data: {
    token_transacao: string;
    transacionado_em: Date;
  }) {
    return fetchApi({
      url: `/comissao/list/pagamento_manual`,
      method: 'post',
      data: {
        ...data,
        id_transacoes: transferenciasSelecionadas,
      },
      messages: {
        error: 'Erro ao salvar pagamanto manual, tente novamente!',
        loading: 'Salvando pagamento manual, aguarde...',
        success: 'Pagamento manual salvo com sucesso!',
      },
      onSuccess: () => {
        formPagamentoManual.resetFields();
        setIdTransacaoPagamentoManual(false);
      },
    });
  }

  function pagarComissoes(todas: boolean) {
    form.setFieldsValue({ transfer_all: todas });
    form.submit();
  }

  function findByTypeAccount(tipo: string) {
    switch (tipo) {
      case 'CHECKING':
        return 'Conta-corrente';

      case 'SAVINGS':
        return 'Conta-corrente';

      default:
        return '';
    }
  }

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'usuario_nome',
      key: 'usuario_nome',
      render: (item: string, data: any) => (
        <Link to={`/usuario/${data.id_usuario}`}>{item}</Link>
      ),
    },
    {
      title: 'Agencia',
      dataIndex: 'agencia',
      key: 'agencia',
      render: (item: string) => (
        <Tooltip placement="top" title="Agencia Prudentte">
          {item && <Tag>{item}</Tag>}
        </Tooltip>
      ),
    },
    {
      title: 'Conta',
      dataIndex: 'conta',
      key: 'conta',
      render: (item: string) => (
        <Tooltip placement="top" title="Conta Prudentte">
          {item && <Tag color="green">{item}</Tag>}
        </Tooltip>
      ),
    },
    {
      title: 'Tipo de Conta',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (item: string) => findByTypeAccount(item),
    },
    {
      title: 'Valor',
      dataIndex: 'valor_comissao',
      key: 'valor_comissao',
      render: (item: number) => dinheiroMask(item),
    },
    {
      title: 'Porcentagem',
      dataIndex: 'pct_comissao',
      key: 'pct_comissao',
      render: (item: number) => porcentagemMask(item),
    },
    {
      title: 'Criado em',
      dataIndex: 'criado_em',
      key: 'criado_em',
      render: (item: string) => format(parseISO(item), 'dd/MM/yyyy HH:mm:ss'),
    },
    {
      title: 'Abrir Carrinho',
      dataIndex: 'id_carrinho_checkout',
      key: 'id_carrinho_checkout',
      width: 100,
      render: (item: string, row: any) =>
        permissoes.CARRINHO_INFORMACAO ? (
          <Link className="ant-btn" to={`/${row.is_checkout ? 'checkout' : 'carrinho'}/${item}`}>
            <MdOpenInNew />
          </Link>
        ) : (
          <Button disabled>
            <MdOpenInNew />
          </Button>
        ),
    },
    {
      title: 'Remover',
      dataIndex: 'id_transacao',
      key: 'id_transacao',
      width: 90,
      render: (item: string) =>
        permissoes.COMISSAO_REMOVER_COMISSAO ? (
          <Popconfirm
            placement="topRight"
            title="Tem certeza que deseja remover essa transação"
            onConfirm={() => handleRemoveComissao(item)}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger>
              <MdCancel />
            </Button>
          </Popconfirm>
        ) : (
          <Tooltip
            placement="topRight"
            title="Você não tem permissão para remover comissão!"
          >
            <Button danger disabled>
              <MdCancel />
            </Button>
          </Tooltip>
        ),
    },
  ];

  function handleResetForm() {
    setQueryParams({});
    form_filters.resetFields();
    setFiltros({});
  }

  function handleUpdatePagination(pagina: number, porPagina?: number) {
    handleSetFiltroValues({ pagina, porPagina });
  }

  return (
    <>
      <PageHeader
        title="Comissões Pendentes"
        breadcrumb={['Carrinho', 'Comissões', 'Comissões Pendentes']}
      />

      {permissoes.COMISSAO_PAGAR_COMISSAO ? (
        <CardCollapse header={false}>
          <Form
            form={form}
            layout="vertical"
            onFinish={data => handleSubmitComissao(data)}
          >
            <Row gutter={16}>
              <Col flex={4}>
                <Form.Item
                  label="Senha de Acesso"
                  name="senha"
                  rules={[
                    { required: true, message: 'Esse campo é obrigatório' },
                  ]}
                >
                  <Input.Password
                    onKeyDown={e =>
                      e.keyCode === 13 ? e.preventDefault() : ''
                    }
                  />
                </Form.Item>
                <Form.Item name="transfer_all" hidden>
                  <Checkbox>Checkbox</Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Popconfirm
                  placement="top"
                  title="Tem certeza que deseja pagar essas comissões?"
                  onConfirm={() => pagarComissoes(false)}
                  okText="Sim"
                  cancelText="Não"
                  disabled={!transferenciasSelecionadas.length}
                >
                  <Button
                    type="primary"
                    style={{ marginTop: 35, marginRight: 10 }}
                    disabled={!transferenciasSelecionadas.length}
                  >
                    Fazer transações selecionadas (
                    {dashinfo.qtde_transferencias})
                  </Button>
                </Popconfirm>
                <Popconfirm
                  placement="top"
                  title="Tem certeza que deseja pagar todas as comissões?"
                  onConfirm={() => pagarComissoes(true)}
                  okText="Sim"
                  cancelText="Não"
                  disabled={
                    !comissoesPendentes.data?.length ||
                    !!transferenciasSelecionadas.length
                  }
                >
                  <Button
                    style={{ marginTop: 34 }}
                    disabled={
                      !comissoesPendentes.data?.length ||
                      !!transferenciasSelecionadas.length
                    }
                  >
                    Fazer todas as transações (
                    {comissoesPendentesDashboard.qtde_transferencias})
                  </Button>
                </Popconfirm>
              </Col>
              <Col>
                <Form.Item label="Pagamento Manual:" name="pagamento">
                  <Button
                    type="primary"
                    onClick={() => setIdTransacaoPagamentoManual(true)}
                    disabled={!transferenciasSelecionadas.length}
                  >
                    Pagamento de comissão ({dashinfo.qtde_transferencias})
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </CardCollapse>
      ) : null}

      <Row gutter={20}>
        <Col md={7} xs={24}>
          <Card
            title="Valor Total das Tranferências"
            value={dashinfo.valor_comissao}
            money
            loading={loading}
            hover={false}
          />
        </Col>
        <Col md={7} xs={24}>
          <Card
            title="Valor Disponível Banco"
            value={dashinfo.saldo}
            type={dashinfo.saldo_type}
            money
            loading={loading}
            hover={false}
          />
        </Col>
        <Col md={5} xs={24}>
          <Card
            title="Qtde. Comissões"
            value={dashinfo.qtde_comissoes}
            loading={loading}
            hover={false}
          />
        </Col>
        <Col md={5} xs={24}>
          <Card
            title="Qtde. Tranferências"
            value={dashinfo.qtde_transferencias}
            loading={loading}
            hover={false}
          />
        </Col>
      </Row>

      <br />

      <CardCollapse title="Filtros">
        <Form
          layout="vertical"
          onFinish={data => handleSetFiltroValues(data)}
          form={form_filters}
        >
          <Row gutter={16}>
            <Col md={6} xs={12}>
              <Form.Item name="nome" label="Nome">
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={12}>
              <Form.Item name="data" label="Data">
                <RangePicker
                  format="DD/MM/YYYY"
                  placeholder={['Data Inicio', 'Data Fim']}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <br />

          <Row justify="end">
            <Button onClick={handleResetForm} className="buttonMarginRight">
              Limpar
            </Button>
            <Button type="primary" htmlType="submit">
              Filtrar
            </Button>
          </Row>
        </Form>
      </CardCollapse>

      <Modal
        visible={idTransacaoPagamentoManual}
        title="Pagamento Manual"
        footer={false}
        onCancel={() => setIdTransacaoPagamentoManual(false)}
        centered
      >
        <div
          className="scrollable-container"
          style={{
            height: transferenciasSelecionadas.length > 1 ? 160 : 100,
            overflow: 'auto',
            border:
              transferenciasSelecionadas.length > 2
                ? '1px solid rgba(140, 140, 140, 0.35)'
                : '',
          }}
        >
          <List
            bordered
            dataSource={transferenciasSelecionadas || []}
            renderItem={item => (
              <List.Item key={item}>
                <FaUserAlt style={{ marginRight: 8 }} />
                <Paragraph
                  copyable={{ tooltips: false }}
                  style={{ marginTop: 12 }}
                >
                  {item}
                </Paragraph>
              </List.Item>
            )}
          />
        </div>
        <Divider />
        <p>
          Utilize essa funcionalidade apenas quando o pagamento da comissão for
          realizado fora do sistema! Caso contrário, impedirá o pagamento da
          comissão pelo sistema
        </p>

        <Form
          form={formPagamentoManual}
          layout="vertical"
          onFinish={data => handlePagamentoManual(data)}
        >
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item label="Código de Liquidação" name="token_transacao">
                <Input maxLength={45} />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Data do Pagamento" name="transacionado_em">
                <DatePicker
                  showTime
                  format="DD/MM/YYYY HH:mm:ss"
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col md={12} />
            <Col md={12}>
              <br />
              <Button htmlType="submit" type="primary" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <br />

      <Table
        columns={columns}
        size="small"
        loading={loading}
        dataSource={comissoesPendentes.data}
        pagination={{
          current: comissoesPendentes.pagina,
          total: comissoesPendentes.total,
          pageSize: comissoesPendentes.porPagina,
          pageSizeOptions: [25, 100, 250, 500],
          onChange: handleUpdatePagination,
        }}
        rowSelection={
          permissoes.COMISSAO_PAGAR_COMISSAO
            ? {
                type: 'checkbox',
                selectedRowKeys: transferenciasSelecionadas,
                onChange: (selectedRowKeys: React.Key[]) => {
                  setTransferenciasSelecionadas(selectedRowKeys as string[]);
                },
              }
            : undefined
        }
      />
    </>
  );
};

export default ComissaoPesquisar;
